<template>
  <div
    v-dragscroll="!isTouchDevice"
    @dragscrollstart="start"
    @dragscrollend="end"
    @click.capture="click"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ mode?: 'stopPropagation' | 'preventDefault' }>(), {
  mode: 'preventDefault',
});

// Avoid emitting click event when scrolldragging
let dragging = false;
let timer: ReturnType<typeof setTimeout> | null = null;

const isTouchDevice = ref(false);

function start() {
  timer = setTimeout(() => (dragging = true), 100);
}

function end() {
  if (timer) {
    clearTimeout(timer);
  }
  setTimeout(() => (dragging = false));
}

function click(event: MouseEvent) {
  if (!dragging) return;
  if (props.mode === 'stopPropagation') {
    event.stopPropagation();
  } else {
    event.preventDefault();
  }
}

onMounted(() => {
  isTouchDevice.value = window.matchMedia('(pointer: coarse)').matches;
});
</script>
<style>
div::-webkit-scrollbar {
  display: none;
}
div {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
